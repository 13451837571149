<template>
    <div>
        <NewUserForm />
    </div>
</template>

<script>
import NewUserForm from '@/components/forms/NewUserForm.vue'
export default {
  name: 'RegistrationPage',
  components: {
      NewUserForm
  }
}
</script>
