<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Enexo
      </h2>
      <p class="mt-2 text-center text-sm text-gray-600">
        You should have received a validation code vid SMS or e-mail. Please enter that code below to validate your account.
      </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

        <form class="space-y-6" action="#" method="POST" @submit.prevent="handleSubmit">
          <p class="mt-2 text-center text-sm text-blue-400">{{ message }}</p>
          <div>
            <p class="block text-sm font-medium text-gray-700">{{ email }}</p>
          </div>


          <div>
            <label for="validationCode" class="block text-sm font-medium text-gray-700">
              Validation Code
            </label>
            <div class="mt-1">
              <input id="validationCode" v-model="validationCode" name="validationCode" type="number" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
          </div>

          <div>
            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Submit
            </button>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
            </div>

            <div class="text-sm">
              <a href="#" v-on:click="resendValidationCode" class="font-medium text-indigo-600 hover:text-indigo-500">
                Request a new code
              </a>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewUserForm',
  components: {
  },
  props: {
    email: String,
  },
  data() {
    return{
      validationCode: '',
      message: '',
    }
  },
  methods: {
    handleSubmit() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "username": this.email,
        "validationCode": this.validationCode.toString(),
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch("https://auth.services.redsmart.group/auth/validate", requestOptions)
        .then(response => response.text())
        .then(result => {
          if (result[0] === '{') {
            result = JSON.parse(result)
            console.log(result)
            this.messageColor = 'text-red-500'
            this.message = result.message
          } else {
            console.log(result)
            this.messageColor = 'text-green-500'
            this.message = `Account successfully Validated. you can now log in to enexo.io`
          }
        })
        .catch(error => {
          console.log('error', error)
          this.messageColor = 'text-red-500'
          this.message= 'An error occurred.'
        });
    },
    resendValidationCode() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "username": this.email,
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch("https://auth.services.redsmart.group/auth/resend", requestOptions)
        .then(response => response.text())
        .then(result => {
          if (result[0] === '{') {
            result = JSON.parse(result)
            console.log(result)
            this.messageColor = 'text-red-500'
            this.message = result.message
          } else {
            console.log(result)
            this.messageColor = 'text-green-500'
            this.message = `Please check your e-mails at ${this.email} for your new validation code.  Note, you can no longer verify your account via SMS.`
          }
        })
        .catch(error => {
          console.log('error', error)
          this.messageColor = 'text-red-500'
          this.message= 'An error occurred.'
        });
    },
  }
}

</script>
