<template>
    <div>
        <ValidationPageForm />
    </div>
</template>

<script>
import ValidationPageForm from '@/components/forms/ValidationPageForm.vue';

export default {
  name: 'ValidationPage',
  components: {
    ValidationPageForm,
  }
}
</script>
