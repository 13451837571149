import { createWebHistory, createRouter } from 'vue-router';
import RegistrationPage from './components/pages/RegistrationPage.vue';
import ValidationPage from './components/pages/ValidationPage.vue';
import ValidateByEmail from './components/pages/ValidateByEmail.vue';

const routes = [
	{
		path: '/',
		name: 'RegistrationPage',
		component: RegistrationPage,
	},
    {
		path: '/validate/',
		name: 'ValidationPage',
		component: ValidationPage,
	},
	{
		path: '/validate/:email',
		name: 'ValidateByEmail',
		component: ValidateByEmail,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;