<template>
    <div>
        <ValidateByEmailForm :email="email" />
    </div>
</template>

<script>
import ValidateByEmailForm from '@/components/forms/ValidateByEmailForm.vue'

export default {
  name: 'ValidationByEmail',
  components: {
      ValidateByEmailForm
  },
  data() {
      return {
          email: ''
      }
  },
  created() {
      this.email = this.$route.params.email
  }
}
</script>
