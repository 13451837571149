<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Enexo
      </h2>
      <p class="mt-2 text-center text-sm text-gray-600">
        Sign up to use our service
      </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

        <p class="text-center" v-bind:class="messageColor">{{ message }}</p>

        <p class="text-center my-5" v-if="registrationSuccess">
          <router-link class="font-bold" :to="{ name: 'ValidateByEmail', params: {email: emailRef} }">Click here</router-link> to enter your one time code.
        </p>

        <form class="space-y-6" action="#" method="POST" @submit.prevent="handleSubmit">
          <div>
            <label for="first_name" class="block text-sm font-medium text-gray-700">
              First name
            </label>
            <div class="mt-1">
              <input id="first_name" v-model="user.first_name" name="first_name" type="text" autocomplete="given-name" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
          </div>
          
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              Email address
            </label>
            <div class="mt-1">
              <input id="email" v-model="user.email" name="email" type="email" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
          </div>

          <div>
            <label for="password" class="block text-sm font-medium text-gray-700">
              Password
            </label>
            <div class="mt-1">
              <input id="password" v-model="user.password" name="password" type="password" autocomplete="current-password" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
          </div>

          <SwitchGroup as="div" class="flex items-center">
            <Switch v-model="enabled" :class="[enabled ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
              <span class="sr-only">Use setting</span>
              <span aria-hidden="true" :class="[enabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
            </Switch>
            <SwitchLabel as="span" class="ml-3">
              <span class="text-sm font-medium text-gray-900">Verify by SMS </span>
              <span class="text-sm text-gray-500">(email by default)</span>
            </SwitchLabel>
          </SwitchGroup>

          <div v-if="enabled">
            <label for="tel" class="block text-sm font-medium text-gray-700">
              Mobile number
            </label>
            <div class="mt-1">
              <input id="tel" v-model="user.tel" name="tel" type="tel" pattern="^\d{11}$" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
          </div>

          <div>
            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Create Account
            </button>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
            </div>

            <div class="text-sm">
              <a href="https://enexo.redsmart.app/" class="font-medium text-indigo-600 hover:text-indigo-500">
                Already registered?
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'

export default {
  name: 'NewUserForm',
  components: {
    Switch,
    SwitchGroup,
    SwitchLabel,
  },
  setup() {
    const enabled = ref(false)
    return {
      enabled,
    }
  },
  data() {
    return{
      user: {
        first_name: '',
        email: '',
        password: '',
        tel: '',
      },
      emailRef: '',
      message: '',
      messageColor: '',
      registrationSuccess: false,
    }
  },
  computed: {
    computedTel() {
      return '44' + this.user.tel.substring(1)
    }
  },
  methods: {
    resetFields() {
      this.user.first_name = ''
      this.user.email = ''
      this.user.password = ''
      this.user.tel = ''
    },
    handleSubmit() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "first_name": this.user.first_name,
        "username": this.user.email,
        "password": this.user.password,
        "validation_method": this.enabled ? "SMS": "email",
        "tel": this.enabled ? this.user.tel.toString(): 'none',
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch("https://auth.services.redsmart.group/auth/register", requestOptions)
        .then(response => response.text())
        .then(result => {
          if (result[0] === '{') {
            result = JSON.parse(result)
            console.log(result)
            this.messageColor = 'text-red-500'
            this.message = result.message
          } else {
            console.log(result)
            this.messageColor = 'text-green-500'
            this.message = `Account Created! You will receive a code by ${this.enabled ? 'SMS' : 'email'} which you will need to validate your account.`
            this.emailRef = this.user.email
            this.$router.push(`/validate/${this.user.email}`)
            this.registrationSuccess = true
            this.resetFields()
            
          }
        })
        .catch(error => {
          console.log('error', error)
          this.messageColor = 'text-red-500'
          this.message= 'An error occurred.'
          this.resetFields()
        });
    }
  }
}
</script>
