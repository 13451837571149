<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Enexo
      </h2>
      <p class="mt-2 text-center text-sm text-gray-600">
        Please tell us which account you are trying to validate
      </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

        <form class="space-y-6" action="#" method="POST" @submit.prevent="handleSubmit">
          
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              Email address
            </label>
            <div class="mt-1">
              <input id="email" v-model="email" name="email" type="email" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            </div>
          </div>

          <div>
            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Go to validation
            </button>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
            </div>

            <div class="text-sm">
              <a href="https://enexo.redsmart.app/" class="font-medium text-indigo-600 hover:text-indigo-500">
                Already validated?
              </a>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router.js'

export default {
  name: 'NewUserForm',
  components: {
  },
  data() {
    return{
      email: '',
    }
  },
  methods: {
    handleSubmit() {
      router.push({
        name: 'ValidateByEmail',
        params: { email: this.email },
      })
    }
  }
}
</script>